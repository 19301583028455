import {Link, useParams} from "react-router-dom";
import AppLayout from "../Components/AppLayout";

const Error = () => {
    const params = useParams();

    return (
        <AppLayout>
            <h3>Oh no... That's not good...</h3>

            <p>
                We can't verify with Microsoft... Please try again in a few minutes.
            </p>

            <p>
                <small>{params.errorCode ? (params.errorCode === 'em-1' ? 'Authentication with Microsoft failed with the error code of {//TODO}' : 'Failed to authenticate with Microsoft...') : 'Unknown error...'}</small>
            </p>

            <Link to="/" className="button">
                Try again
            </Link>
        </AppLayout>
    );
};

export default Error;
