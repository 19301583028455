import {useSearchParams} from "react-router-dom";
import MsLogo from "../assets/mslogo.svg";
import AppLayout from "../Components/AppLayout";
import {settings} from "../Utils/Settings";
import {useRequester} from "../Hooks/useRequester";

const Login = ({url}) => {
    return <>
        <p>
            Lets get you logged into Minecraft through your Microsoft account. This
            should be quick! Press login below to start.
        </p>
        <a href={url} className="button pushed-top">
            <img src={MsLogo} alt="Microsoft Logo" />
        </a>
    </>
}

const AppClosedError = () => <>
    <h3>We can't find the FTB App!</h3>
    <p>
        This could be because you've closed the app, the login dialog, or because your browser is blocking our request.<br /><br /><em>If you're using Brave, please disable Brave shield and refresh.</em>
    </p>
</>

const ApiDownError = () => <>
    <h3>Something's gone wrong!</h3>
    <p>Failed to communicate with our servers... Please try again in a few minutes... If this continues to happen, please contact use on <a href="https://go.ftb.team/discord">Discord</a>.</p>
</>

const Main = () => {
    const [data] = useSearchParams();

    const bypassEnabled = data.get("bypass") != null || data.get("useNew") != null;

    let useNew = data.get("useNew") != null;
    const newFlowSession = sessionStorage.getItem("newFlow");
    if (newFlowSession != null && data.get("useNew") == null) {
        useNew = newFlowSession === "true";
    } else {
        sessionStorage.setItem("newFlow", data.get("useNew") != null ? "true" : "false");
    }

    const {loading: appLoading, error: appError} = useRequester("http://localhost:7755", {
        mode: 'no-cors',
        timeout: 5000
    }, true);

    const {data: apiData, loading: apiLoading, error: apiError} = useRequester(`${settings.apiUrl}/auth${useNew ? "?useNew=true" : ""}`, {});

    const isLoading = apiLoading || appLoading;
    const errored = !!(!useNew && !bypassEnabled && appError?.message) || apiError?.message || !apiData?.url;

    if (errored) {
        return <AppLayout>
            {appError ? <AppClosedError /> : <ApiDownError />}
        </AppLayout>
    }

    return (
        <AppLayout>
            {isLoading ? <div>Loading {apiLoading ? "Authentication server..." : "Waiting for app to be ready"}</div> : <Login url={apiData.url} />}
        </AppLayout>
    );
};

export default Main;
