import "./App.scss";
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Error from "./Routes/Error";
import Callback from "./Routes/Callback";
import Main from "./Routes/Root";

const App = () => (
  <main className="main">
    <BrowserRouter>
      <Routes>
        <Route path="/error/:errorCode" element={<Error />} />
        <Route path="/callback" element={<Callback />} />
        <Route path="/" element={<Main />} />
      </Routes>
    </BrowserRouter>
  </main>
);

export default App;
