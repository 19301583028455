import Logo from "../assets/logo.svg";

const AppLayout = ({ children }) => {
    const today = new Date().getFullYear();

    return (
        <>
            <div className="auth-in-a-box">
                <div className="the-box">
                    <header>
                        <img src={Logo} alt="FTB Logo" />
                    </header>
                    <div className="body">{children}</div>
                </div>
            </div>
            <footer>
        <span>
          © {today} <a href="https://feed-the-beast.com">Feed the Beast Ltd</a>
        </span>
                -
                <span>
          Having an issue? Join the{" "}
                    <a href="https://ftb.team/discord">Discord</a>
        </span>
            </footer>
        </>
    );
};

export default AppLayout
