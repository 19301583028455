import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import AppLayout from "../Components/AppLayout";
import {settings} from "../Utils/Settings";

const Callback = () => {
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState(true);
    const [success, setSuccess] = useState(null);

    const code = searchParams.get("code");
    const state = searchParams.get("state");
    const navigate = useNavigate();

    const [result, setResult] = useState(null);

    useEffect(() => {
        const authenticate = async () => {
            try {
                const req = await fetch(`${settings.apiUrl}/authenticate`, {
                    method: "POST",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        code,
                        state
                    }),
                });

                const res = await req.json();
                if (!res.ok && res.error) {
                    navigate(`/error/${res.error}`);
                } else {
                    setResult(res);

                    if (res.credentials) {
                        window.location.href = `ftb://auth/process?credentials=${res.credentials}`;
                        setSuccess(true);
                    } else {
                        // Old way of authenticating
                        try {
                            await fetch("http://localhost:7755", {
                                mode: "no-cors",
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                    iv: res.iv,
                                    key: res.key,
                                    password: res.password,
                                }),
                            });

                            setSuccess(true);
                        } catch (e) {
                            setSuccess(false);
                        }
                    }

                    window.history.replaceState(
                        null,
                        "Successfully authenticated",
                        "/callback"
                    );
                }
            } catch (e) {
                console.log(e);
                setSuccess(false);
                navigate(`/error/unknown`);
            } finally {
                setLoading(false);
            }
        };

        authenticate().catch(console.error);
    }, [code, state, navigate]);

    return (
        <AppLayout>
            {loading ? (
                <div>Loading...</div>
            ) : success ? (
                <div>
                    <h3>You're in!</h3>

                    <p>You can now safely close this tab and return to the FTB App.</p>

                    {result?.credentials && <>
                        <h4>Didn't work? No problem, copy the code below and enter it into the app!</h4>
                        <code>{result.credentials}</code>
                    </>}
                </div>
            ) : (
                <div>
                    <h3>Failed to authenticate...</h3>
                    <Link to="/" className="button">
                        Try again
                    </Link>
                </div>
            )}
        </AppLayout>
    );
};

export default Callback;
