import {useEffect, useReducer} from "react";
import {fetchWithTimeout} from "../Utils/Utils";

export const useRequester = (url, fetchOptions, useRaw = false) => {
    const defaultState = {
        error: null,
        data: null,
        loading: false
    }

    const [state, dispatch] = useReducer((state, action) => {
        switch (action.type) {
            case 'loading': return { ...state, loading: true };
            case 'not-loading': return { ...state, loading: false };
            case 'data': return { ...state, data: action.payload, error: null }
            case 'error': return { ...state, error: action.payload, data: null }
            default: return state;
        }
    }, defaultState);

    useEffect(() => {
        const request = () => {
            dispatch({ type: "loading"});
            fetchWithTimeout(url, {...fetchOptions, credentials: 'include'})
                .then(raw => {
                    if (!raw.ok) {
                        dispatch({
                            type: "error",
                            payload: {
                                message: `Api request to [${url}] failed`,
                                code: raw.status,
                                raw: null,
                                url
                            }
                        });
                        return null;
                    }
                    return useRaw ? raw.text() : raw.json();
                })
                .then(data => dispatch({type: "data", payload: data}))
                .catch(e => dispatch({type: "error", payload: {
                    raw: e,
                    message: e.message,
                    url,
                    code: e.status
                }}))
                .finally(() => dispatch({type: "not-loading"}))
        }

        void request();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url])

    return state
}
